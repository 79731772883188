import * as React from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { HexColorPicker } from "react-colorful";
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import MuiButton from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
import ImportIntegrationsDrawer from '../Forms/ImportIntegrationsDrawer';

const GET_APPOINTMENT_TYPES = gql`
query GetAppointmentTypesTable($userId: uuid!, $limit: Int!, $offset: Int!, $searchFilter: String!) {
  appointment_type(limit: $limit, offset: $offset, where: {organization: {users: {id: {_eq: $userId}}}, _or: [{name: {_ilike: $searchFilter}}, {source_name: {_ilike: $searchFilter}}]}, order_by: {name: asc, created_at: asc}) {
    id
    name
    source_name
    linked_type_id
    requires_resource
    requires_team_member
    enable_scheduling
    enable_recommendations
    show_waiting_room
    show_in_office_view
    show_in_marketing_view
    can_reschedule
    can_cancel
    color
    linked_type {
      id
      name
    }
    integration_provider {
      name
    }
  }
  appointment_type_aggregate {
    aggregate {
      count
    }
  }
  user_by_pk(id: $userId) {
    organization_id
  }
  linkable: appointment_type(where: {organization: {users: {id: {_eq: $userId}}}, enable_scheduling: {_eq: true}}, order_by: {name: asc}) {
    id
    name
    source_id
    source_name
    organization_id
    minute_duration
    requires_resource
    requires_team_member
  }
}`;

const UPDATE_APPOINTMENT_TYPE = gql`
mutation UpdateAppointmentType($id: uuid!, $name: String!, $requires_resource: Boolean!, $requires_team_member: Boolean!, $show_waiting_room: Boolean!, $enable_scheduling: Boolean!, $enable_recommendations: Boolean!, $show_in_office_view: Boolean!, $show_in_marketing_view: Boolean!) {
  update_appointment_type_by_pk(pk_columns: {id: $id}, _set: {name: $name, requires_resource: $requires_resource, requires_team_member: $requires_team_member, show_waiting_room: $show_waiting_room, enable_scheduling: $enable_scheduling, enable_recommendations: $enable_recommendations, show_in_office_view: $show_in_office_view, show_in_marketing_view: $show_in_marketing_view}) {
    id
  }
}`;

const UPDATE_APPOINTMENT_COLOR = gql`
mutation UpdateAppointmentType($id: uuid!, $color: String!) {
  update_appointment_type_by_pk(pk_columns: {id: $id}, _set: {color: $color}) {
    id
  }
}`;

const UPDATE_LINKED_TYPE = gql`
mutation UpdateLinkedAppointmentType($id: uuid!, $linkId: uuid!) {
  update_appointment_type_by_pk(pk_columns: {id: $id}, _set: {linked_type_id: $linkId}) {
    id
  }
}`;

const IMPORT_APT_TYPES = gql`mutation ImportAptTypes($userId: uuid!, $orgId: uuid!, $orgIntegrationId: uuid!) {
  importAptTypes(args: {userId: $userId, orgId: $orgId, orgIntegrationId: $orgIntegrationId}) {
    error
    message
  }
}`;

const DELETE_APT_TYPE = gql`mutation DeleteAptType($id: uuid!) {
  delete_appointment_type_by_pk(id: $id) {
    id
  }
}`;

const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'bgcolor',
})(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor,
  '&:hover': {
    backgroundColor: bgcolor,
  },
}));

const EnhancedTableToolbar = (props) => {
  const { openImport, setOpenImport, numSelected, setFilter, handleDelete } = props;

  return (
    <Toolbar
      sx={{
        mb: { xs: 2 },
        pl: { sm: 2 },
        pr: { xs: 0 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <React.Fragment>
          <Typography
            sx={{ width: 250 }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Appointments Types
          </Typography>
          <Grid container spacing={3} sx={{ flex: '1 1 100%' }}>
            <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
              <Button variant="contained" size='small' sx={{ ml: 4 , width: 200 }} onClick={() => setOpenImport(!openImport)}>Import</Button>
            </Grid>
          </Grid>
          <TextField id="search-basic" autoFocus={true} label="search" variant="outlined" onChange={(e) => {setFilter(`%${e.target.value}%`)}} />
        </React.Fragment>
      )}

      {numSelected > 0 && 
        <Tooltip title="Delete">
          <IconButton onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      }
    </Toolbar>
  );
};

export default function AppointmentTypes(props) {
  const [limit, setLimit] = React.useState(30);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [filter, setFilter] = React.useState('%%');
  const [selected, setSelected] = React.useState([]);
  const [active, setActive] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [color, setColor] = React.useState(null);
  const [rowId, setRowId] = React.useState('');
  const [openImport, setOpenImport] = React.useState(false);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleColorClick = (event, c, rowId) => {
    setAnchorEl(event.currentTarget);
    setColor(c);
    setRowId(rowId);
  };

  const handleColorClose = () => {
    setAnchorEl(null);
    setColor(null);
    setRowId('');
  };

  const handleChooseColor = (value) => {
    updateAptColor({ variables: { id: rowId, color: value } });
    setAnchorEl(null);
    setColor(null);
    setRowId('');
  };

  const { data } = useQuery(GET_APPOINTMENT_TYPES, {
    variables: { userId: Memory.id, limit: limit, offset: offset, searchFilter: filter },
  });

  const [updateAptType] = useMutation(UPDATE_APPOINTMENT_TYPE, {
    refetchQueries: [
      {
          query: GET_APPOINTMENT_TYPES,
          variables: { userId: Memory.id, limit: limit, offset: offset, searchFilter: filter },
      }
    ]
  });

  const [updateAptColor] = useMutation(UPDATE_APPOINTMENT_COLOR, {
    refetchQueries: [
      {
          query: GET_APPOINTMENT_TYPES,
          variables: { userId: Memory.id, limit: limit, offset: offset, searchFilter: filter },
      }
    ]
  });

  const [updateLinkedType] = useMutation(UPDATE_LINKED_TYPE, {
    refetchQueries: [
      {
          query: GET_APPOINTMENT_TYPES,
          variables: { userId: Memory.id, limit: limit, offset: offset, searchFilter: filter },
      }
    ]
  });

  const [importTypes] = useMutation(IMPORT_APT_TYPES, {
    refetchQueries: [
      {
          query: GET_APPOINTMENT_TYPES,
          variables: { userId: Memory.id, limit: limit, offset: offset, searchFilter: filter },
      }
    ]
  });

  const [deleteType] = useMutation(DELETE_APT_TYPE, {
    refetchQueries: [
      {
          query: GET_APPOINTMENT_TYPES,
          variables: { userId: Memory.id, limit: limit, offset: offset, searchFilter: filter },
      }
    ]
  });

  const emptyRows = !!data && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.appointment_type_aggregate.aggregate.count) : 0;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.appointment_type.map((c) => c.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setOffset(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);
    setPage(0);
  };

  const handleDelete = () => {
    selected.forEach((s) => deleteType({ variables: {id: s} }));
    setSelected([]);
  }

  const handleClick = (event, id) => {
    // console.log('event', event);
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Box
        sx={{
            width: '100%',
            height: '100%',
        }}
    >
      <EnhancedTableToolbar openImport={openImport} setOpenImport={setOpenImport} numSelected={selected.length} setFilter={setFilter} handleDelete={handleDelete} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={!!data && selected.length > 0 && selected.length < data.appointment_type.length}
                  checked={!!data && data.appointment_type.length > 0 && selected.length === data.appointment_type.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all appointment types',
                  }}
                />
              </TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Internal Name</TableCell>
              <TableCell align="left">Source</TableCell>
              <TableCell align="center">Linked Appointment Type</TableCell>
              <TableCell align="center">Requires Room</TableCell>
              <TableCell align="center">Requires Team Member</TableCell>
              <TableCell align="center">Enable Waiting Room</TableCell>
              <TableCell align="center">Enable Scheduling</TableCell>
              <TableCell align="center">Enable Recommendations</TableCell>
              <TableCell align="center">Enable Office</TableCell>
              <TableCell align="center">Enable Marketing</TableCell>
              {/* <TableCell align="center">Enable Reschedule</TableCell>
              <TableCell align="center">Enable Cancel</TableCell> */}
              <TableCell align="center">Color</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!data && data.appointment_type_aggregate.aggregate.count > 0 && !!data.appointment_type && data.appointment_type.length > 0 && 
            data.appointment_type.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  onMouseEnter={()=>{setActive(row.id)}}
                  onMouseLeave={()=>{setActive('')}}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                      onClick={(event) => handleClick(event, row.id)}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.source_name}</TableCell>
                  <TableCell align="left">{!!row.integration_provider && !!row.integration_provider.name ? row.integration_provider.name : null}</TableCell>
                  {/* {active === row.id ? */}
                    <TableCell align="center" sx={{ minWidth: '220px' }}>
                      <TextField
                          id="appointment-type-input"
                          select
                          fullWidth
                          label="Appointment Type"
                          value={row.linked_type_id ?? ''}
                          onChange={(e) => updateLinkedType({ variables: { id: row.id, linkId: !!e.target.value ? e.target.value : null} })}
                      >
                          {data.linkable.map((type) => (
                              <MenuItem key={type.id} value={type.id}>
                                  {`${type.name} - ${type.source_name}`}
                              </MenuItem>
                          ))}
                          <MenuItem key='none' value='' />
                      </TextField>
                    </TableCell>
                  {/* :
                    <TableCell align="center">{!!row.linked_type_id ? row.linked_type.name  : ''}</TableCell>
                  } */}
                  <TableCell align="center">
                    <Checkbox checked={row.requires_resource} onClick={(e) => updateAptType({ variables: { id: row.id, name: row.name, requires_resource: e.target.checked, requires_team_member: row.requires_team_member, show_waiting_room: row.show_waiting_room, enable_scheduling: row.enable_scheduling, enable_recommendations: row.enable_recommendations, show_in_office_view: row.show_in_office_view, show_in_marketing_view: row.show_in_marketing_view } })}/>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox checked={row.requires_team_member} onClick={(e) => updateAptType({ variables: { id: row.id, name: row.name, requires_resource: row.requires_resource, requires_team_member: e.target.checked, show_waiting_room: row.show_waiting_room, enable_scheduling: row.enable_scheduling, enable_recommendations: row.enable_recommendations, show_in_office_view: row.show_in_office_view, show_in_marketing_view: row.show_in_marketing_view } })}/>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox checked={row.show_waiting_room} onClick={(e) => updateAptType({ variables: { id: row.id, name: row.name, requires_resource: row.requires_resource, requires_team_member: row.requires_team_member, show_waiting_room: e.target.checked, enable_scheduling: row.enable_scheduling, enable_recommendations: row.enable_recommendations, show_in_office_view: row.show_in_office_view, show_in_marketing_view: row.show_in_marketing_view } })}/>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox checked={row.enable_scheduling} onClick={(e) => updateAptType({ variables: { id: row.id, name: row.name, requires_resource: row.requires_resource, requires_team_member: row.requires_team_member, show_waiting_room: row.show_waiting_room, enable_scheduling: e.target.checked, enable_recommendations: row.enable_recommendations, show_in_office_view: row.show_in_office_view, show_in_marketing_view: row.show_in_marketing_view } })}/>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox checked={row.enable_recommendations} onClick={(e) => updateAptType({ variables: { id: row.id, name: row.name, requires_resource: row.requires_resource, requires_team_member: row.requires_team_member, show_waiting_room: row.show_waiting_room, enable_scheduling: row.enable_scheduling, enable_recommendations: e.target.checked, show_in_office_view: row.show_in_office_view, show_in_marketing_view: row.show_in_marketing_view } })}/>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox checked={row.show_in_office_view} onClick={(e) => updateAptType({ variables: { id: row.id, name: row.name, requires_resource: row.requires_resource, requires_team_member: row.requires_team_member, show_waiting_room: row.show_waiting_room, enable_scheduling: row.enable_scheduling, enable_recommendations: row.enable_recommendations, show_in_office_view: e.target.checked, show_in_marketing_view: row.show_in_marketing_view} })}/>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox checked={row.show_in_marketing_view} onClick={(e) => updateAptType({ variables: { id: row.id, name: row.name, requires_resource: row.requires_resource, requires_team_member: row.requires_team_member, show_waiting_room: row.show_waiting_room, enable_scheduling: row.enable_scheduling, enable_recommendations: row.enable_recommendations, show_in_office_view: row.show_in_office_view, show_in_marketing_view: e.target.checked} })}/>
                  </TableCell>
                  {/* <TableCell align="center">
                    <Checkbox disabled checked={row.can_reschedule}/>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox disabled checked={row.can_cancel}/>
                  </TableCell> */}
                  <TableCell align="center">
                    <Button variant="contained" bgcolor={row.color} onClick={(e) => handleColorClick(e, row.color, row.id)}></Button>
                  </TableCell>
                </TableRow>
              )})
            }
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (33) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[30, 60, 90]}
        component="div"
        count={!!data ? data.appointment_type_aggregate.aggregate.count : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleColorClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <HexColorPicker color={color != null ? color : '#000000'} onChange={handleChooseColor} />
      </Popover>
      <ImportIntegrationsDrawer  
        open={openImport}
        setOpen={setOpenImport}
        importFunction={importTypes}
      />
    </Box>
  );
}
